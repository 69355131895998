.article-front {

  &:nth-child(odd) {
    padding-right: 30px;
  }

  &:nth-child(even) {
    padding-left: 30px;
  }

  @media screen and (max-width: 767px) {

    &:nth-child(odd),
    &:nth-child(even) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &:not(:first-child) {
      margin-top: 50px;
    }
  }

  .article-front__title {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .article-front__img {
    > img {
      height: 300px;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      display: block;

      > img {
        margin: 0 auto;
      }
    }
  }

  .article-front__read-more {
    color: $color-brand;
    font-size: 1.8rem;

    ._text {
      margin-left: 10px;
      color: $color-brand;
    }
  }

}

.article-row {
  &:not(:first-child) {
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
  }
}
