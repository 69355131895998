.article-single {
  border-bottom: 2px solid $color-brand;
  padding-top: 20px;
  padding-bottom: 50px;

  .article-single__title {
    font-size: 50px;
    font-weight: bold;
    margin: 0;

    a {
      font-size: inherit;
      color: inherit;

      &:hover {
        color: $color-brand;
        transition: color 300ms ease-in;
      }
    }
  }

  .article-single__date {
    font-weight: 300;
    font-size: 1.3rem;
    font-style: italic;
  }

  .article-single__thumb {
    margin-top: 50px;
  }

  .article-single__content {
    margin-top: 25px;
  }
}

.article-single--archive {

  .article-single__title {
    font-size: 25px;
  }

  .article-single__thumb {
    margin-top: 20px;
  }
}

.post__single__content {

  img {
    width: auto;
  }
}
.aligncenter
{
  display: block;
  margin: 5px auto 5px auto;
}