.pagination {

  ul {

    > li {

      display: inline-block;
      list-style-type: none;
      text-align: center;
      background-color: $color-brand;
      color: $color-white;

      &.active {
        background-color: $color-text-light;
      }

      > a {
        $size: 50px;
        width: $size;
        height: $size;
        line-height: $size / 2;
        padding: 10px;
        color: inherit;
        display: inline-block;
      }
    }
  }
}
