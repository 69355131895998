.bg {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  height: 700px;
  margin-bottom: 100px;
  padding-top: 20px;
  position: relative;

  @media screen and(max-width: 991px) {
    height: 500px;
  }

  @media screen and(max-width: 480px) {
    height: 400px;
    margin-bottom: 30px;
  }

  canvas {
    position: absolute;
    z-index: -1;
  }

  .header__intro {
    position: absolute;
    left: 50%;
    top: 260px;
    max-width: 780px;
    margin-left: -390px;
    text-align: center;

    @media screen and(max-width: 767px) {
      top: 180px;
      text-align: center;
      max-width: 100%;
      width: 100%;
      margin-left: -50%;
    }
  }

  .header__title {
    font-size: 5rem;
    font-weight: bold;

    @media screen and(max-width: 767px) {
      font-size: 3.5rem;
      padding-top: 30px;
    }

  }

  .header__sub-title {
    font-size: 1.8rem;

    @media screen and(max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  .header__let-talk {
    position: absolute;
    right: 0;
    top: 20px;
    background-color: $color-brand;
    color: $color-white;

    a {
      display: inline-block;
      color: inherit;
      padding: 20px;
    }

    @media screen and(max-width: 991px) {
      display: none;
    }

  }

  .header__let-talk--list {
    display: none;
    right: 20px;
  }

  .header__contact-type {
    float: left;
    font-size: 1.6rem;

    &:not(:first-child) {
      margin-left: 20px;

      @media screen and(max-width: 991px) {
        margin-left: 10px;
      }
    }

    ._icon {
      margin-right: 5px;
      margin-left: 10px;
    }

    @media screen and(max-width: 991px) {
      font-size: 1.3rem;
    }
  }

  .header__brand {
    margin-top: 10px;
    @media screen and(max-width: 991px) {
      margin-top: 30px;
    }

    @at-root .header__brand--sticky {
      display: none;
    }
  }

  .header__nav-mobile {
    display: none;
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 3rem;

    &:hover,
    &:focus,
    &:active {
      color: $color-brand;
    }

    @media screen and(max-width: 991px) {
      display: inline-block;
    }
  }

  .header__nav {

    ._close {
      display: none;
    }

    .menu-item {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
    }

    ul {
      padding: 10px;
      margin-top: 20px;
      display: block;
      color: $color-text;
      font-size: 1.6rem;
      text-align: center;

      > li {
        list-style-type: none;
        display: inline-block;

        &.let-talk {
          padding-top: 10px;
          padding-bottom: 10px;
          display: none;
          background-color: $color-brand;
          color: $color-white;
        }

        > a {
          padding: 0 20px;
          color: inherit;
          font-size: inherit;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    @media screen and(max-width: 991px) {
      position: fixed;
      right: -100%;
      top: 0;
      width: 80%;
      height: 100%;
      background-color: darken($color-text, 20%);
      display: block;
      margin-top: 0;
      transition: right 300ms ease-in;
      z-index: 9999;
      overflow-y: scroll;

      ._close {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 3.2rem;
        display: inline-block;
        color: $color-white;
      }

      .menu-item {
        position: relative;
        top: 0;
        left: 0;
        font-size: 2.5rem;
        display: inline-block;
        color: $color-white;
      }


      &.active {
        right: 0;
      }

      ul {
        margin-top: 50px;
        font-size: 3.2rem;
        color: $color-white;

        @media screen and(max-width: 480px) {
          font-size: 2rem;
        }

        > li {
          display: block;
          text-align: center;

          &.let-talk {
            display: block!important;
            position: relative;
            right: 0;
          }
        }
      }
    }
  }
}

.header--single {
  margin-bottom: 0;
  padding-top: 5px;
  height: 100px;

  .header__let-talk {
    top: 15px;
  }

  .header__brand {
    float: left;
    margin-top: 0;
    display: block;
  }
}

.headroom {

  &.headroom--pinned.headroom--not-top {
    top: 0;
    left: 0;
    position: fixed;
    background-color: $color-white;
    padding: 0 15px;
    border-bottom: 3px solid $color-brand;
    width: 100%;
    z-index: 9998;
    height: 100px;

    @media screen and(max-width: 991px) {
      padding: 15px;
    }

    .header__nav-mobile {
      position: static;
      float: right;
    }

    .header__nav {

      ul {

        > .let-talk {
          display: inline-block;
        }
      }

    }

    .header__let-talk--sticky {
      display: inline-block;
    }

    .header__brand--sticky {
      display: block;
      margin-top: 20px;
      float: left;

      > img {
        height: 65px;
      }

      @media screen and(max-width: 991px) {
        margin-top: 0;

        .header__nav {

          ul {

            > .let-talk {
              display: block;
            }
          }

        }
      }

    }

  }

  .headroom--top {
    position: static;
  }
}

.headroom--pinned {
  .header__let-talk--list{
    display: inline-block;
  }
}

.header__brand--sticky {

  .img-responsive {
    height: 60px;
  }
}

.item-hot-line {

  ._text {
    display: inline-block;
  }
  ._phone {
    display: inline-block;
    a {
      display: inline-block;
      span {
        display: inline-block;
        color: #0E6BAD !important;
        font-size: 25px !important;
        font-weight: 600;
        text-align: left;
        text-decoration: none !important;
        cursor: pointer;
      }
    }
  }

  @media screen and(max-width: 768px) {

    a {
      display: block;
    }
    ._phone {
      display: block !important;
    }
  }
}
