.input {
  display: block;
  width: 100%;
  border: 0;
  background-color: $color-white;
  outline: none;
  margin: 20px 0 0;
  border-bottom: 2px solid $color-brand;
  font-size: inherit;
}
