.staff {
  text-align: center;

  .staff__img {

    > img {
      max-width: 150px;
      max-height: 150px;
      width: 100%;
      margin: 0 auto;
      display: block;
      border-radius: 50%;
    }
  }

  .staff__name {

    margin: 20px 0 0;
    font-size: 2.8rem;
    font-weight: bold;
  }

  .staff__title {
    position: relative;
    padding-top: 20px;
    margin: 20px 0 0;
    font-size: 1.8rem;
    color: $color-text-light;

    ._dot {
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: -5px;
      margin-left: -5px;
      width: 10px;
      height: 10px;
      background-color: $color-brand-3;
      border-radius: 50%;

    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 50px;
      height: 2px;
      background-color: $color-text-light;
    }

    &::after {
      top: 0;
      right: 50%;
      margin-right: -70px;
    }

    &::before {
      top: 0;
      left: 50%;
      margin-left: -70px;
    }
  }
}

.staff-row {
  &:not(:first-child) {
    margin-top: 50px;
  }
}
