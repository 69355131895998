@import 'helper/helper';
@import 'common/variable';
@import 'component/button';
@import 'component/input';
@import 'component/form';
@import 'component/popup';
@import 'component/pagination';
@import 'block/header';
@import 'block/section';
@import 'block/we-do';
@import 'block/achievement';
@import 'block/staff';
@import 'block/article';
@import 'block/client';
@import 'block/social';
@import 'block/let-talk';
@import 'block/article-single';
@import 'block/sidebar';

body {
  color: $color-text;
  font-family: $font-1;
}

html {
  font-size: 10px;
}

p,
span,
a {
  color: $color-text-light;
  font-family: $font-2;
  font-size: 1.5rem;
}

a {
  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.page-title {
  font-size: 4rem;
  font-weight: bold;

  @media screen and(max-width: 480px) {
    font-size: 3rem;
  }
}

.page-sub-title {
  font-size: 2.8rem;
  font-weight: bold;
  color: $color-brand-2;
  position: relative;
  margin-bottom: 30px;

  &::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    content: '';
    width: 50px;
    height: 2px;
    background-color: $color-brand-2;
  }
}

.slider {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 90%;
  margin: 0 auto;

  img {
    max-width: 90%;
  }

  .slider__next {
    right: 0;
  }

  .slider__prev {
    left: 0;
  }

  .slider__next,
  .slider__prev {
    font-size: 30px;

    @media screen and(max-width: 767px) {
      font-size: 15px;

    }

    & > i {
      color: $color-brand;
    }

    &::before {
      content: '';
    }
  }

  .slider__dot {
    bottom: -50px;

    @media screen and(max-width: 767px) {
      bottom: -25px;
    }

    li {

      > button {
        background-color: $color-text;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: none;

        &::before {
          font-size: 30px;
          content: '';
        }
      }

      &.slick-active {

        > button {
          background-color: $color-brand;

          &::before {
            color: $color-brand;
          }
        }
      }
    }
  }
}

.let {
  padding-left: 0;

  > li {
    list-style-type: none;
    display: block;
    margin-bottom: 10px;

    > a {
      color: $color-white;
      font-size: 1.6rem;
      display: inline-block;
      position: relative;

      ._icon {
        margin-right: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 15px;
        width: 0;
        height: 3px;
        background-color: $color-brand;
        transition: width 200ms ease-in-out;
      }

      &:hover {

        &::before {
          width: 100%;
        }
      }
    }
  }
}

.footer-copy {
  margin-top: 20px;
  font-size: 10px;
  padding: 20px 0;
  text-align: left;
  color: $color-white;
  margin-bottom: -20px;

  background-color: $color-text;

  p {
    margin: 0;
  }
}

.scroll-top {
  position: fixed;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 20px;
  bottom: 100px;
  z-index: 9997;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
  color: $color-brand;
  border-radius: 50%;
  border: 2px solid $color-brand;
}

.img-post-new {
  width: 100%;
  height: 330px;

  @media screen and(max-width: 900px) {
    height: 300px;
  }

  @media screen and(max-width: 767px) {
    height: auto;
  }
}

.title-new-post {
  padding-top: 10px;

  a {
    font-size: 24px;
    font-weight: bold;
  }

}

.page-sub-title {
  color: #E8B71A !important;
}

.footer-contact {
  i {
    font-size: 20px;
    padding: 0 10px;
  }

  a {
    font-size: 1.5rem;
    color: #fff;

    i {
      font-size: 20px;
      padding: 10px;
    }
  }
}