
.btn {
  border-radius: 0;
  padding: 20px;
  transition: background-color 200ms ease-in-out;
}

.btn-brand {
  background-color: $color-brand;
  color: $color-white;

  &:hover,
  &:focus,
  &:active {
    color: $color-white;
    background-color: darken($color-brand, 5%);
  }

  ._icon {
    margin-left: 10px;
  }
}

.btn-readmore {
  padding: 15px 25px;
  background-color: $color-brand;
  color: $color-white;
  border: medium none;
  &:hover {
    background-color: $color-brand;
    color: $color-white;
    box-shadow: 1px 1px 3px $color-brand-3;

  }
}


