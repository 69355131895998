.we-do {

  text-align: center;

  @media screen and(max-width: 991px) {

    margin-top: 30px;
  }

  .we-do__img {
    background-color: $color-brand;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    border-radius: 50%;
    width: 150px;
    height: 150px;

    > img {
      margin-top: 12.5px;
      height: 75px;
    }
  }

  .we-do__img--staff {
    background-color: transparent;
    padding-bottom: 0;
    width: auto;
    height: auto;

    > img {
      margin-top: 0;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }
  }

  .we-do__title {
    margin: 20px 0 0;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  }

  .we-do__intro {
    margin-top: 15px;
    font-size: 13px;
    text-align: justify;
  }
  .we-do__list{
    list-style-type: decimal;
    text-align: justify;
    padding-left: 20px;
    color: #3d3d3d;
  }

}
