.form {

  .form__submit {
    margin-top: 20px;
    min-width: 100px;
  }

  br {
    display: inline;
  }
}
