.social {
  margin-top: 20px;
  padding: 0;

  > li {
    list-style-type: none;
    float: left;
    border: 1px solid $color-white;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    transition: background 200ms;
    margin-right: 5px;
    margin-bottom: 5px;

    > a {
      font-size: 2.2rem;
      color: inherit;
      transition: color 200ms;
    }

    &:hover {
      background-color: $color-brand;
      transition: background 200ms;

      > a {
        color: $color-white;
      }
    }
  }
}
