.let-talk {

  p {
    font-size: 2rem;
  }

  .let-talk__title {
    font-size: 3.5rem;
    font-weight: bold;
  }

  .let-talk__form {
    margin-top: 50px;
    font-size: 2rem;
  }

  .let-talk__form-submit {
    padding: 15px 40px;
    font-size: 2rem;
  }
}
