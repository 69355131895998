.section {
  padding-top: 50px;
  padding-bottom: 50px;

  .section__title {
    margin: 0;
    text-transform: uppercase;

    ._title {

      position: relative;

      ._dot {
        position: absolute;
        bottom: -12.5px;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px;
        width: 10px;
        height: 10px;
        background-color: $color-brand;
        border-radius: 50%;

      }

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: 50px;
        height: 2px;
        background-color: $color-brand;
      }

      &::after {
        bottom: -10px;
        right: 50%;
        margin-right: -70px;
      }

      &::before {
        bottom: -10px;
        left: 50%;
        margin-left: -70px;
      }
    }
  }

  .section__content {
    padding-top: 50px;
  }

}

.section__archive__title {
  padding-top: 0;
}

.section--footer {
  background-color: $color-brand;
  padding-top: 0;
  padding-bottom: 0;
  color: $color-white;
  p {
    color: $color-white;
  }
}

.copyright__text {
  font-size: 12px;

  @media screen and(max-width: 768px){
    padding-bottom: 20px;
  }
}
