.popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  overflow-y: auto;
  background-color: $color-white;

  &.active {
    display: block;
  }

  .popup__title {
    font-size: 3rem;

    @media screen and(max-width: 767px) {
      font-size: 2rem;
    }
  }

  .popup__content {
    position: relative;
    margin: 30px auto;
    padding: 20px 0;
    width: 90%;
    background-color: $color-white;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  }

  .popup__close {
    font-size: 2.5rem;
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

.popup--full {

  .popup__content {
    margin: 0 auto;
    width: 100%;
    box-shadow: none;
  }
}
