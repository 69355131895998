$color-text: #171717;
$color-text-light: lighten($color-text, 15%);
$color-white: #fff;
$color-brand: #196bac;
$color-brand-2: #e8b71a;
$color-brand-3: #262626;
$color-brand-4: #CDCDCD;
$font-1: 'Arimo', sans-serif;
$font-2: 'Open Sans', sans-serif;

