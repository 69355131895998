.blog_sec {
  padding: 0;
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px darkgray;
  box-sizing: border-box;

  .row-article {
    min-height: 210px;
  }
}

.article-single--archive {

  .media-body {
    padding: 0 10px;

    h2 {
      margin: 0;
    }

    .article-single__title {

      font-weight: bold;
      color: $color-brand;
      margin: 0;
      webkit-transition: color .75s ease-in-out;
      -moz-transition: color .75s ease-in-out;
      -ms-transition: color .75s ease-in-out;
      -o-transition: color .75s ease-in-out;
      transition: color .75s ease-in-out;

      &:hover {
        text-decoration: none ;
        color: $color-brand-2 ;
      }
    }
  }
}

.entry-content {

  .readmore-area {
    text-align: right;
    margin-top: 30px;
  }
}

.achievement {
  max-width: 95%;
  margin: 0 auto;
  padding: 0  20px 0 10px;

  .achievement__title {
      margin: 10px 0;
      padding-top: 0;
      font-weight: bold;
      font-size: 2.5rem;

  }

  .achievement__text {
    width: 40%;
    box-sizing: border-box;
    text-align: justify;

    li {
      margin-left: 15px;
    }

    @media screen and(max-width: 767px) {
      width: 100%;
      margin-top: 20px;
      padding-right: 0;
    }
  }

  .achievement__img {
    width: 60%;
    box-sizing: border-box;

    img {
      width: 100%;
      max-height: 550px;
    }

    @media screen and(max-width: 767px) {
      width: 100%;
      margin-top: 0;
      padding-right: 0;
    }
  }
}

.video_preview {
  margin-left: 10px;

  .img-responsive {
    width: 280px;
    height: 200px;
  }
}

.achievement-slider {
  max-width: 90%;
  margin: 0 auto;

  @media screen and(max-width: 767px) {
    max-width: 95%;
  }

  .achievement-slider__next {
    right: -50px;

    @media screen and(max-width: 767px) {
      right: -25px;
    }
  }

  .achievement-slider__prev {
    left: -50px;

    @media screen and(max-width: 767px) {
      left: -25px;
    }
  }

  .achievement-slider__next,
  .achievement-slider__prev {
    font-size: 30px;

    @media screen and(max-width: 767px) {
      font-size: 15px;

    }

    & > i {
      color: $color-brand;
    }

    &::before {
      content: '';
    }
  }

  .achievement-slider__dot {
    bottom: -50px;

    @media screen and(max-width: 767px) {
      bottom: -25px;
    }

    li {

      > button {
        background-color: $color-text;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: none;

        &::before {
          font-size: 30px;
          content: '';
        }
      }

      &.slick-active {

        > button {
          background-color: $color-brand;

          &::before {
            color: $color-brand;
          }
        }
      }
    }
  }
}
